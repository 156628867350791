<template>
  <el-container class="virtual-agent-tracker h-full">
    <page-header
      style="padding: 20px 30px"
      :title="__('Live Virtual Agent Tracker')"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
    ></page-header>
    <component
      v-bind:is="component"
      style="width: 100%;padding: 20px 30px;"
    ></component>
  </el-container>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import _ from "lodash";
import BackButtonHandler from "@/components/BackButtonHandler";
import { mapActions } from "vuex";

export default {
  name: "VirtualAgentTrackerIndex",

  components: {
    PageHeader
  },

  mixins: [BackButtonHandler],

  data() {
    return {
      selectedComponent: "live",
      selectedIndex: null,
      navItems: [
        {
          label: __("Live"),
          active: true,
          path: "live",
          icon: "icon-virtual-agent-tracker.svg"
        },
        {
          label: __("Maximum"),
          active: false,
          path: "maximum",
          icon: "icon-virtual-agent-tracker.svg"
        }
      ]
    };
  },

  computed: {
    component() {
      return this.importComponent(this.selectedComponent);
    }
  },

  methods: {
    ...mapActions("app", {
      changeUserSelectedAccountId: "changeUserSelectedAccountId"
    }),

    importComponent(path) {
      return () =>
        import("@/views/analyse/virtual-agent-tracker/" + path + "/index.vue");
    },

    async backButtonHandler(to, from, isBackButton) {
      if (
        isBackButton &&
        (this.$route.params.returnToAll ||
          this.$route.params.from_ac_id === "all")
      ) {
        await this.changeUserSelectedAccountId("all");
      }
      return true;
    },

    handleNavItemChanged(navItem) {
      this.selectedComponent = navItem.path;
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      _.find(this.navItems, { label: navItem.label }).active = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.virtual-agent-tracker {
  display: flex;
  flex-direction: column;
}
</style>
